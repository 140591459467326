:root{
    /* missing vars */
    --bs-btn-disabled-bg: #e9ecef;
    // --bs-btn-disabled-color: #ced4da;
    --bs-btn-disabled-border-color: #ced4da;
}

// Body
$body-bg: #fff;

// Typography
$font-family-sans-serif: 'Open Sans', sans-serif;
$font-size-base: .75rem;
$line-height-base: 1.6;

// Theme
$primary:       #083854;
$puce-color:    #f50c65;
$danger:        #ff6707;
$secondary:     #6c757d;
$success:       #198754;
$info:          #0dcaf0;
$warning:       #ffc107;
$light:         #f8f9fa;
$dark:          #212529;
$primary-color: $primary;
$secondary-color: $puce-color;

// Calculated
$primary-dark-color: darken($primary-color, 13%);
$primary-light-color: lighten($primary-color, 13%);
$secondary-dark-color: darken($secondary-color, 13%);
$secondary-light-color: lighten($secondary-color, 13%);

// Override boostrap theme
$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark,
  "puce":       $puce-color
);

$table-variants: (
  "primary":    transparentize($primary, .8),
  "secondary":  transparentize($secondary, .8),
  "success":    transparentize($success, .8),
  "info":       transparentize($info, .8),
  "warning":    transparentize($warning, .8),
  "danger":     transparentize($danger, .8),
  "puce":       transparentize($puce-color, .8),
  "light":      $light,
  "dark":       $dark,
);


// On aime pas les coins ronds !!!
$border-radius: 3px;
$border-radius-sm: 3px;

// Bootstrap tooltip
$tooltip-font-size: $font-size-base;

// Boostrap modal z-index, needs to be higher than header navbar's 1200
$--bs-modal-zindex: 1040;
