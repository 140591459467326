#jobFormula {
    .modal-body {
        .rows {
            .row {
                &:not(:last-child) {
                    border-bottom:1px solid $secondary;
                }

                &.active {
                    background:$light;
                }

                .dragRowPosition {
                    cursor:grab;
                }

                .elem {
                    font-size:.75rem;
                    line-height:1;
                }
            }
        }
    }
}