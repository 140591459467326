// Fonts
@import 'fonts';

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-free/scss/solid.scss';

// tooltip design
@import 'tippy.js/dist/tippy.css';

// table format excel
@import  "~tabulator-tables/dist/css/tabulator_simple";

//quill
@import "~quill/dist/quill.snow.css";

//tagify
@import "~@yaireo/tagify/dist/tagify.css";

// fancy select with search
@import  "~@selectize/selectize/dist/css/selectize.bootstrap5";

@import '~jquery-ui/themes/base/all.css';

@import '~bootstrap-select/dist/css/bootstrap-select.min.css';

@import 'layouts';
@import 'style';
@import 'tasksheeting';
@import 'timesheeting';
@import 'logs';
@import 'projects';
@import 'quality-control';
@import 'treeview';

@import '~jquery-ui/themes/base/all.css';

@import '~bootstrap-select/dist/css/bootstrap-select.min.css';

@import '~datatables.net-bs5/css/dataTables.bootstrap5.min.css';
