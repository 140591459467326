.container.logs {
    .list {
        .header.row {
            .col {
                font-weight: bold;
                font-size: 1.3rem;
            }
        }

        .pagination {
            margin: 1rem 0 0 0; justify-content: end;
        }
    }
}