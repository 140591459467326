.tasksheeting {
    .col-form-label, .header { font-weight:bold; }

    #column_fields, .work_unit_values, .jobs {
        // .col, .col-1, .col-sm-1 {
        //     min-width: 10em !important;
        //     width: 18em !important;
        // }
        // .col-2, .col-sm-2 { /* = .col width x2 */
        //     min-width: 10em !important;
        //     width: 36em !important;
        // }
        // .col-3, .col-sm-3 { /* = .col width x3 */
        //     min-width: 15em !important;
        //     width: 54em !important;
        // }
        // .col-4, .col-sm-4 { /* = .col width x4 */
        //     min-width: 15em !important;
        //     width: 72em !important;
        // }
        // .col-1.text-end{ /* list action buttons */
        //     width:10em !important;
        // }
        .modal .col-2 {
            min-width: initial !important;
            width: initial !important;
        }
        .actions{ /* task action buttons */
            width:18em !important;
        }
    }
}
