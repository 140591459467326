/************************************************/
/*****             TREEVIEW                 *****/

.treeview-colorful {
    font-size: 16px;
    font-weight: 400;

    &.w-20 {
        width: 20rem;
    }

    hr {
        border-color: #a2127a;
    }

    h6 {
        font-size: 1.4em;
        font-weight: 500;
        color: #a2127a;
    }

    ul {
        position: relative;
        padding-left: 0;
        list-style: none;
    }

    .treeview-colorful-list {
        ul {
            padding-left: 1em;
            background: #f8f8f8;
        }
    }

    .treeview-colorful-element {
        padding: 0.2em 0.2em 0.2em 1em;
        cursor: pointer;
        border: 2px solid transparent;
        border-right: 0 solid transparent;
        transition: all 0.1s linear;
        color: black;

        &:hover {
            background-color: #eee;
        }

        .fas {
            position: relative;
            color: black;
            transition: all 0.2s linear;
            transform: rotate(90deg);
        }

        .fa-check-circle {
            position: relative;
            color: green;
            transition: all 0.2s linear;
            transform: rotate(0deg);
        }
    }

    .treeview-colorful-items {
        border-bottom: solid 1px gray;

        a:hover,
        a:focus {
            text-decoration: none !important;
        }
    }

    .treeview-colorful-items-header {
        display: block;
        padding: 0.4em;
        margin-right: 0;
        border-bottom: 2px solid transparent;
        transition: all 0.1s linear;

        &:hover {
            background-color: #eee;
        }

        &.open {
            background-color: #ddd;
            border-bottom: 2px solid #f50c65;
            transition: all 0.1s linear;

            span {
                color: black;
            }

            &:hover {
                color: #eee;
                background-color: #ddd;
            }

            div:hover {
                background-color: #ddd;
            }
        }

        .fa-angle-right {
            font-size: 0.8rem;
            transition: all 0.2s linear;
        }

        span {
            color: black;
        }

        .fas {
            position: relative;
            color: #f50c65;
            transition: all 0.2s linear;
            transform: rotate(90deg);
        }

        .fa-minus-circle {
            position: relative;
            color: #f50c65;
            transition: all 0.2s linear;
            transform: rotate(180deg);
        }
    }
}