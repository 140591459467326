#facturation-template,
#forecast-template {
    display: none;
}

.pointer {
    cursor: pointer;
}

.grab {
    cursor: grab;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

.loader-spinner {
    position: relative;
    z-index: 1;

    &::before {
        position: absolute;
        z-index: 10000;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #fff;
        opacity: 0.75;
        content: "";
    }

    &::after {
        position: absolute;
        z-index: 10001;
        top:0; right:0; bottom:0; left:0;
        margin:auto;
        content:' ';
        height:calc(100% - 10px); max-height:65px;
        aspect-ratio:1 / 1;
        background:url('/images/spinner-solid.svg') no-repeat;
        animation: rotation 2s infinite linear;
    }
}

.amsterixme {
    display:inline-block; vertical-align:middle;
    font-size:1.3rem; line-height: 1rem; color:$puce-color;
}

.bg-pink-pepsi {
    background-color: $puce-color !important;
    color: #fff !important;
}
.bg-strip-secondary {
    background: repeating-linear-gradient(-45deg, #efefef, #efefef 2px, transparent 1px, transparent 23px);
}

.options-button-group {
    padding-left: 10px;
    padding-top: 10px;
}

.options-large-button {
    min-width: 200px;
}

.pull-left {
    float: left !important;
}

.trello-cost-icon {
    border-radius: 5px;
    background-color: #33cccc;
    padding: 2px 10px;
    color: white;
    margin-right: 15px;

    & .big {
        padding: 6px 15px;
    }
}

.trello-status-icon {
    border-radius: 5px;
    background-color: #03224c;
    padding: 2px 10px;
    color: white;
    margin-right: 15px;

    & .big {
        padding: 6px 15px;
    }
}

.soft-red {
    color: $puce-color;
}

.text-missing {
    color: red;
}

.text-blue {
    color: var(--bs-blue);
}

.untouched-text {
    text-decoration: none;
}

.comment-x-short-scroll {
    overflow-y: auto;
    padding: 20px;
    height: 190px;
    text-align: justify;
    padding-left: 5px !important;
    border: 1px solid #ccc;
}

.comment-large-scroll {
    overflow-y: auto;
    padding: 20px;
    height: 476px;
    text-align: justify;
}

.comment-medium-scroll {
    overflow-y: auto;
    padding: 20px;
    height: 328px;
    text-align: justify;
}

.nav-link-trello.active.nav-link-trello-somber {
    background-color: #eee !important;
}

.nav-link-trello.active {
    background-color: #f5f5f5 !important;
}

.nav-link-trello {
    border: solid 2px #ddd !important;
    color: inherit;
    margin-right: 2px;
    margin-bottom: 5px !important;
    margin-top: 22px;
}

.comment-short-scroll {
    overflow-y: auto;
    padding: 20px;
    height: 300px;
    text-align: justify;
}

.dashboard-widget-title {
  font-family: 'Courier New', Courier, monospace;
  font-size:22px;
}

.dashboard-widget-subtitle {
  font-family: 'Courier New', Courier, monospace;
  font-size:14px;
  margin-bottom: 15px;
  padding-left: 40px;
}

.dashboard-widget-header {
  background-color: #eee;
  padding: 5px;
}

.dashboard-widget-sub-header {
  background-color: #ccc;
  padding: 5px;
}

.dashboard-widget-pepsi-header {
  background-color: $puce-color;
  color: #eee;
  padding: 5px;
}

.dashboard-widget-content {
  padding: 10px;
}

.dashboard-widget-desc {
  background-color: #ddd;
  padding: 10px;
}

.dashboard-widget-content-wrapper {
  padding: 15px;
  background-color: #f0f0f0;
}

.dashboard-widget-content-chain {
  padding: 10px;
  border: 0.05rem solid lightgray;
  background-color: #fafafa;
}

.dashboard-widget {
  margin-bottom: 15px !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.dashboard-widget-no-shadow {
  margin-left: 15px !important;
  margin-bottom: 15px !important;
  margin-right: 15px !important;
  border: 1px solid #ddd;
}

.card-link {
    color: $puce-color;
}

.align-self-center {
    align-self: center;
    justify-content: center;
    display: flex;
}

.tooltips {
    min-width: 20px !important;
}

.progressbar-1 {
    background-color: #4A878F;
}

.progressbar-2 {
    background-color: #DE99BC;
}

.progressbar-3 {
    background-color: #83D3DE;
}

.progressbar-4 {
    background-color: #DED16D;
}

.progressbar-5 {
    background-color: #BBDE6D;
}

.progressbar-6 {
    background-color: #E07967;
}

.colortimesheeting-1 {
    color: #4A878F;
}

.colortimesheeting-2 {
    color: #DE99BC;
}

.colortimesheeting-3 {
    color: #83D3DE;
}

.colortimesheeting-4 {
    color: #DED16D;
}

.colortimesheeting-5 {
    color: #BBDE6D;
}

.colortimesheeting-6 {
    color: #E07967;
}

.account-block {
    min-height: 500px;
}

.sortable-chosen {
    background:$light;
}

.table-fixed {
    table-layout: fixed;
}

.modal-xxl {
    --bs-modal-width:1500px;
}

.modal-xxxl {
    --bs-modal-width:1700px;
}

.modal {
    --bs-modal-margin: 4.75rem;
}

.tooltip-large {
    .tooltip-inner {
        max-width:500px !important;
    }
}
.tooltip-extra-large {
    .tooltip-inner {
        min-width: fit-content !important;
        max-width:90% !important;
    }
}

.tooltip-alert {
  --bs-tooltip-bg: orange;
  --bs-tooltip-color: black;
  --bs-tooltip-opacity: 1;
}

.btn-primary.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
    background-color: var(--bs-blue);
}

/* fix bootstrap-select default sizes */
.bootstrap-select .btn.dropdown-toggle {
    min-height: 33px !important;
    border-color: #ced4da !important;
}
.bootstrap-select .dropdown-menu {
    z-index: 1030;
    li > a {
        min-height: 27px !important;
    }
}

/* invert text color when option has background-color with bootstrap-select */
.bootstrap-select[style^="background-color:#"] > .btn.dropdown-toggle {
    filter: saturate(0) grayscale(1) brightness(.7) contrast(1000%) invert(1); /* field color */
}
.bootstrap-select .dropdown-item[style^="background-color:#"]:not(.selected) > span.text {
    filter: saturate(0) grayscale(1) brightness(.7) contrast(1000%) invert(1);  /* option color */
}

.swal2-container {
    .swal2-html-container.swal2-select-picker {
        position:relative; z-index:2; overflow:visible;
    }
}

.btn-comment {
    position: relative;
    &.small {
        .nb { position:absolute; top:0; }
    }
}

.w-fit {
    width: fit-content;
}

.h-fit {
    height: fit-content;
}

html.swal2-shown,body.swal2-shown { overflow-y:auto !important; height: auto!important;} //avoid SweetAlert2 to scroll the page to the top when fired
