#form-quality-pick {
    .rows {
        .dropdown {
            .dropdown-toggle {
                max-width:100%;

                span {
                    display:inline-block; max-width:100%; vertical-align:middle; overflow:hidden; text-overflow: ellipsis;
                }
            }
        }
    }
}