html {
    cursor: default;
}

// Production Layout
.layout-background {
    //background-image: url('/../images/background.png') !important;
    background-color: white;
}

// Dev Layout
.layout-background-dev {
    background: repeating-linear-gradient( 45deg , lighten($secondary,40%) , lighten($secondary, 20%) 10px, lighten($secondary,30%) 10px, lighten($secondary, 40%) 20px);
}


// Background for home and login page
.layout-login {
    background: url('/../images/logo_ppp.png') no-repeat center center fixed;
    height: 100%;
    overflow: hidden;
}

.title {
    font-size:84px;
}

.top-right {
    position:absolute;
    top:18px;
    right:10px;
}

// Navbar
#app > .navbar {
    z-index: 1030;
    .navbar-brand {
        padding:0;

        .breadcrumb {
            margin-bottom: 0;

            .breadcrumb-item {
                position:relative;
                padding: .5rem .2rem .5rem 0;
                margin:0 11px 0 20px;
                background: $white;

                a {
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }

                &:first-child {
                    margin-left: 0;
                    padding-left: .5rem;
                }

                &:not(:first-child)::before {
                    content: '';
                    border-left: 20px solid transparent;
                    border-top: 20px solid $white;
                    border-bottom: 20px solid $white;
                    border-right: 0;
                    position: absolute;
                    top:0;
                    left: -28px;
                }

                &::after {
                    content: '';
                    border-left: 20px solid $white;
                    border-top: 20px solid transparent;
                    border-bottom: 20px solid transparent;
                    border-right: 0;
                    position: absolute;
                    top:0;
                    right: -20px;
                }

                * {
                    color: $dark;
                    &:hover {
                        color: $dark;
                    }
                }
            }
        }
    }

    .logout {
        margin-right:1rem;
        a {
            text-decoration: none;
            font-size: 1rem;
            color:$light;
            span {
                vertical-align: middle;
            }
        }
    }
}

// Home links
.links {
    color:#636b6f;

    a {
        padding: 0 25px; font-size: 12px; font-weight: 600; color:inherit; letter-spacing: .1rem; text-decoration: none; text-transform: uppercase;
    }

    .card-btn {
        display: inline-block; width: 200px; height: 110px; padding: 12px; margin: 10px; vertical-align: middle;
        font-size: 16px; text-align: center; white-space: normal;
        cursor: pointer; background-color: #f5f5f58c;

        .card-title {
            height: 35px;
        }

        .card-text {
            line-height: 1.3;
        }

        .card-body {
            padding:0;
        }
    }
}

input[type="range"] {
    border:none;
    outline: none;
}

.comment-message {
    background-color: #636b6f;
    color: $light;
    padding: 6px;
    border-radius: 6px;
    width: 90%;
    margin-left: auto;
    display: inline-block;
    white-space: pre-wrap;
    line-height: 1.1rem;
    text-align: left;
    min-height: 32px;

    &-user {
        background-color: $primary;
    }

    &-edit {
        border: none;
        width: fit-content;
    }
}

.comment-list {
    overflow-y: auto;
    max-height: 450px;
}

.badge-warning {
    background-color: $warning;

    &-icon {
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: inline-block;
    }

    .badge-icon {
        width: 100% !important;
        vertical-align: middle;
    }
}

.badge-danger {
    background-color: red;

    &-icon {
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: inline-block;
    }

    .badge-icon {
        width: 100% !important;
        vertical-align: middle;
    }
}

#logout-form {
    margin:0;
}
